<template>
  <div class="partner-custom-form">
    <invoice-addresss-form
      v-if="partner"
      :partner-obj="partner"
      :action="action"
      @clearAction="action = null"
    />
  </div>
</template>

<script>
import InvoiceAddresssForm from "./components/InvoiceAddressForm.vue";

export default {
  components: {
    InvoiceAddresssForm,
  },
  data() {
    return {
      action: null,
      partner: JSON.parse(localStorage.getItem("userData")),
    };
  },
  methods: {
    submitPressed() {
      this.action = "update";
    },
  },
};
</script>
