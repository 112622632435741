<template>
  <div class="content-box">
    <b-form @submit.prevent="onSubmit">
      <div
        :class="{ error: v$.partner.companyName.$errors.length }"
        class="form-group"
      >
        <label for="partner-companyName">
          <strong>{{ $t("partner.companyName") }}</strong>
        </label>
        <div>
          <b-form-input
            id="partner-companyName"
            v-model="partner.companyName"
            name="partner-companyName"
          />
          <div
            class="input-errors"
            v-for="error of v$.partner.companyName.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <div :class="{ error: v$.partner.ico.$errors.length }" class="form-group">
        <label for="partner-ico">{{ $t("partner.ico") }}</label>
        <div>
          <b-form-input
            id="partner-ico"
            v-model="partner.ico"
            name="partner-ico"
          />
          <div
            class="input-errors"
            v-for="error of v$.partner.ico.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <div :class="{ error: v$.partner.dic.$errors.length }" class="form-group">
        <label for="partner-dic">{{ $t("partner.dic") }}</label>
        <div>
          <b-form-input
            id="partner-dic"
            v-model="partner.dic"
            name="partner-dic"
          />
          <div
            class="input-errors"
            v-for="error of v$.partner.dic.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <div class="form-group">
        <b-form-checkbox v-model="partner.dphPayer">
          {{ $t("partner.dph") }}
        </b-form-checkbox>
      </div>

      <div v-if="partner.dphPayer" class="form-group">
        <label for="partner-icdph">{{ $t("partner.icdph") }}</label>
        <b-form-input
          id="partner-icdph"
          v-model="partner.icdph"
          name="partner-icdph"
        />
      </div>

      <div
        :class="{ row, error: v$.partner.registeredAt.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("partner.registeredAt") }}</label>
        <div>
          <b-form-textarea v-model="partner.registeredAt" />
          <div
            class="input-errors"
            v-for="error of v$.partner.registeredAt.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.partner.iban.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("partner.iban") }}</label>
        <div>
          <b-form-input v-model="partner.iban" />
          <div
            class="input-errors"
            v-for="error of v$.partner.iban.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <div
        :class="{ row, error: v$.partner.swift.$errors.length }"
        class="form-group"
      >
        <label for="">{{ $t("partner.swift") }}</label>
        <div>
          <b-form-input v-model="partner.swift" />
          <div
            class="input-errors"
            v-for="error of v$.partner.swift.$errors"
            :key="error.$uid"
          >
            <small class="text-danger">{{ error.$message }}</small>
          </div>
        </div>
      </div>

      <b-alert variant="warning" show>
        {{ $t("partner.addressWarning") }}
      </b-alert>

      <b-button type="submit" variant="primary">{{
        !partner.id
          ? $t("invoice.invoiceAddressCreate")
          : $t("invoice.invoiceAddressUpdate")
      }}</b-button>
    </b-form>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import ListUtils from "@/mixins/ListUtils";
import ResourceUtils from "@/mixins/ResourceUtils";

export default {
  components: {},
  props: {
    partnerObj: { Object, required: true },
    action: { type: String, default: null },
  },
  mixins: [ListUtils, ResourceUtils],
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      partner: {
        companyName: { required: this.$translateError('required') },
        ico: { required: this.$translateError('required') },
        dic: { required: this.$translateError('required') },
        iban: { required: this.$translateError('required') },
        swift: { required: this.$translateError('required') },
        registeredAt: { required: this.$translateError('required') },
      },
    };
  },
  data() {
    return {
      partner: this.partnerObj,
    };
  },
  watch: {
    action() {
      this.onSubmit();
    },
  },
  methods: {
    async onSubmit() {
      const isValid = await this.v$.$validate();
      if (isValid) {
        const body = {
          companyName: this.partner.companyName,
          ico: this.partner.ico,
          dic: this.partner.dic,
          icdph: this.partner.icdph,
          dphPayer: this.partner.dphPayer,
          iban: this.partner.iban,
          swift: this.partner.swift,
          registeredAt: this.partner.registeredAt,
        };

        !this.partner.id
          ? this.create(
              this.$Users,
              body,
              this.$t("partner.created"),
              null,
              null,
              this.error
            )
          : this.update(
              this.$Users,
              this.$route.params.id,
              body,
              this.$t("partner.updated"),
              null,
              null,
              this.error
            );
        this.$emit("clearAction");
      } else {
        this.$emit("clearAction");
      }
    },
    error() {
      this.$emit("clearAction");
    },
  },
};
</script>

<style lang="scss">
@import "vue-select/src/scss/vue-select.scss";
</style>
